import { userService } from '../../helpers/user.service'
import i18n from '../../i18n'

const userModel = {
    _id: '',
    active: false,
    created_at: '',
    created_by: '',
    customer_uuid: '',
    is_admin: false,
    language_code: 'es',
    last_login: '',
    login: '',
    phone: '',
    roles: [],
    updated_at: '',
    updated_by: '',
    user_name: '',
}

export const user = JSON.parse(localStorage.getItem('user'))
export let state = user ? user : userModel


// dispatch
export const actions = {
    addUser({ commit }, user) {
        state = user
        commit('addUser', user)
    },
    async updatePreference({ dispatch, commit }, locale) {
        userService.update(user._id, { language_code: locale })
            .then(
                () => {
                    dispatch('notification/success', 'Preference updated', { root: true })
                    commit('updatePreference', locale)
                },
                error => {
                    dispatch('notification/error', error, { root: true })
                }
            )
    }
}

export const mutations = {
    addUser(stateS, user) {
        stateS = user
    },
    updateUserRequest(state, locale) {
        state = { ...state.user, language_code: locale }
        i18n.global.locale = user.language_code
        localStorage.setItem('user', JSON.stringify(state))
    },
    cleanUser(stateS) {
        // eslint-disable-next-line no-unused-vars
        stateS = userModel
        localStorage.clear()
    },
}

export const getters = {
    user: state => {
        if (state?.roles?.length > 1) {
            return state
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            return user ? user : userModel
        }
    },
}
