import { makeRequestOptions as r } from './utils'

export const roleService = {
  getById: async (id) => r('GET', `/roles/${id}`),
  create: async (data) => r('POST', '/roles', data),
  update: async (id, data) => r('PUT', `/roles/${id}`, data),
  delete: async (id) => r('DELETE', `/roles/${id}`),
  getRolesAvaliable: async () => r('GET', '/roles/avaliable'),
  getPermissions: async () => r('GET', '/roles/permissions'),
  getPermissionsMap: async () => r('GET', '/roles/permissions-map'),
  getAll: async () => r('GET', '/roles'),
}

export const roleModel = {
  role_name: '',
  description: '',
  permissions: [],
  active: true
}