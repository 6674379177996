import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store"

import BootstrapVueNext from 'bootstrap-vue-next'
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts"
import { vMaska } from "maska"

import VueFeather from 'vue-feather'
import Toast from 'vue-toastification'
import { useToast } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import '@/assets/scss/config/interactive/app.scss'
import '@/assets/scss/mermaid.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import Vueform from '@vueform/vueform'
import vueformConfig from './../vueform.config'
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura'
import { definePreset } from '@primevue/themes'

import './assets/css/style.css'

const MyPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '{blue.950}'
        }
    }
})


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

const options = {
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
}

const app = createApp(App)
    .use(Toast, options)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .component(VueFeather.type, VueFeather)
    .directive("maska", vMaska)
    .use(i18n)
    .use(vClickOutside)
    .use(Vueform, vueformConfig)
    .use(PrimeVue, {
        theme: {
            preset: MyPreset,
            options: {
                darkModeSelector: false || 'none',
            }
        },
        locale: {
            startsWith: i18n.global.t('t-startsWith'),
            contains: i18n.global.t('t-contains'),
            notContains: i18n.global.t('t-notContains'),
            endsWith: i18n.global.t('t-endsWith'),
            equals: i18n.global.t('t-equals'),
            notEquals: i18n.global.t('t-notEquals'),
            noFilter: i18n.global.t('t-noFilter'),
            lt: i18n.global.t('t-lt'),
            lte: i18n.global.t('t-lte'),
            gt: i18n.global.t('t-gt'),
            gte: i18n.global.t('t-gte'),
            dateIs: i18n.global.t('t-dateIs'),
            dateIsNot: i18n.global.t('t-dateIsNot'),
            dateBefore: i18n.global.t('t-dateBefore'),
            dateAfter: i18n.global.t('t-dateAfter'),
            custom: i18n.global.t('t-custom'),
            clear: i18n.global.t('t-clear'),
            apply: i18n.global.t('t-apply'),
            matchAll: i18n.global.t('t-matchAll'),
            matchAny: i18n.global.t('t-matchAny'),
            addRule: i18n.global.t('t-addRule'),
            removeRule: i18n.global.t('t-removeRule'),
            accept: i18n.global.t('t-accept'),
            reject: i18n.global.t('t-reject'),
            choose: i18n.global.t('t-choose'),
            upload: i18n.global.t('t-upload'),
            cancel: i18n.global.t('t-cancel'),
            firstDayOfWeek: 0,
            dateFormat: i18n.global.t('t-dateFormat'),
            weak: i18n.global.t('t-weak'),
            medium: i18n.global.t('t-medium'),
            strong: i18n.global.t('t-strong'),
            passwordPrompt: i18n.global.t('t-passwordPrompt'),
            emptyFilterMessage: i18n.global.t('t-emptyFilterMessage'),
            searchMessage: i18n.global.t('t-searchMessage'),
            selectionMessage: i18n.global.t('t-selectionMessage'),
            emptySelectionMessage: i18n.global.t('t-emptySelectionMessage'),
            emptySearchMessage: i18n.global.t('t-emptySearchMessage'),
            emptyMessage: i18n.global.t('t-emptyMessage'),
            aria: {
                trueLabel: i18n.global.t('t-aria.trueLabel'),
                falseLabel: i18n.global.t('t-aria.falseLabel'),
                nullLabel: i18n.global.t('t-aria.nullLabel'),
                pageLabel: i18n.global.t('t-aria.pageLabel'),
                firstPageLabel: i18n.global.t('t-aria.firstPageLabel'),
                lastPageLabel: i18n.global.t('t-aria.lastPageLabel'),
                nextPageLabel: i18n.global.t('t-aria.nextPageLabel'),
                previousPageLabel: i18n.global.t('t-aria.previousPageLabel'),
                selectLabel: i18n.global.t('t-aria.selectLabel'),
                unselectLabel: i18n.global.t('t-aria.unselectLabel'),
                expandLabel: i18n.global.t('t-aria.expandLabel'),
                collapseLabel: i18n.global.t('t-aria.collapseLabel')
            }
        }
    })

// Adiciona o Toast ao contexto global
app.config.globalProperties.$toast = useToast()

app.mount('#app')