import store from "@/state/store"

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authfack/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
          next()
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: "/authentication",
    name: "Authentication",
    component: () => import("../views/account/2fa.vue"),
    meta: {
      title: "Confirm Authentication",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authfack/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authfack/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authfack/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout.vue"),
    meta: {
      title: "Logout",
    },
  },
]