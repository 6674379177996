import { createWebHistory, createRouter } from "vue-router"
import nonMenuRoute from './non-menu'
import { menuItems } from './menu'
import appConfig from "../../app.config"
import { loadingState } from '../loadingState'

const joinRoutes = [...nonMenuRoute, ...menuItems.reduce((routes, menu) => {
  return routes.concat(menu.childrens)
}, [])]

const router = createRouter({
  history: createWebHistory(),
  routes: joinRoutes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ['/login', '/register', '/forgot-password', '/authentication', '/logout']
  const termsPages = ['/pages/privacy-policy', '/pages/term-conditions']
  const isPublicPage = publicPages.includes(routeTo.path)
  const userStorage = localStorage.getItem('user')
  const user = userStorage ? JSON.parse(userStorage) : null
  if (isPublicPage) {
    return next()
  }

  if (user) {
    loadingState.isLoading = true
  }

  if (!user) {
    const userToken = localStorage.getItem('token')
    if (!userToken) {
      return next('/login')
    }
  }

  if (termsPages.includes(routeTo.path)) {
    return next()
  }

  // Se o usuário está acessando uma página pública, verifique se ele aceitou os termos e políticas
  // if (user) {
  //   // Redireciona para a política de privacidade se não tiver sido aceita
  //   if (!user?.accept_privacy) {
  //     return next(`/pages/privacy-policy?redirect=${routeTo.path}`)
  //   }

  //   // Redireciona para os termos e condições se não tiver sido aceito
  //   if (!user?.accept_terms) {
  //     return next(`/pages/term-conditions?redirect=${routeTo.path}`)
  //   }
  // }

  // Prossegue para a rota desejada
  next()
})


router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title
  // If we reach this point, continue resolving the route.
  next()
})

router.afterEach(() => {
  setTimeout(() => {
    loadingState.isLoading = false
  }, 1000) // Pode ajustar o tempo para refletir melhor o tempo de carregamento
})

export default router
