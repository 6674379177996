import { makeRequestOptions as r } from './utils'

export const userService = {
    getAll: async (filters) => r('GET', '/users', filters),
    create: async (data) => r('POST', '/users', data),
    update: async (id, data) => r('PUT', `/users/${id}`, data),
    changePassword: async (id, password) => r('PUT', `/users/${id}/change-password`, password),
    toggleStatus: async (id) => r('PUT', `/users/${id}/toggle-status`),
    updateRoles: async (id, roles) => r('PUT', `/users/${id}`, { roles }),
    getProfile: async () => r('GET', '/profile'),
    changeProfilePassword: async (currentPassword, newPassword) => r('PUT', '/profile/change-password', {
        currentPassword,
        newPassword
    }),
    login: async (login, password) => r('POST', '/login', { login, password }),
    logout: async () => r('POST', '/logout'),
    delete: async (id) => r('DELETE', `/users/${id}`),
}